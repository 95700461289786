import { QueryParamsMappers } from "@utils/mappers";
import { useCallback, useEffect, useState } from "react";

export type QueryParams = QueryParamsMappers;
type QueryParamsToQueryString = (queryParams: QueryParams) => string;
export const queryParamsToQueryString: QueryParamsToQueryString = (
  queryParams
) => {
  Object.keys(queryParams).forEach((key) => {
    if (
      !queryParams[key] ||
      queryParams[key] === "false" ||
      (Array.isArray(queryParams[key]) && queryParams[key]?.length === 0)
    ) {
      delete queryParams[key];
    }

    if (Array.isArray(queryParams[key]) && queryParams[key]?.length === 0) {
      delete queryParams[key];
    }
  });

  const pairs = Object.keys(queryParams).map(
    (key) => `${key}=${queryParams[key]}`
  );

  if (!pairs.length) {
    return "";
  }

  return `?${pairs.join("&")}`;
};

export type SetQueryParams = (queryParams: QueryParamsMappers) => void;

type QueryStringToQueryParams = (queryString: string) => QueryParams;
const queryStringToQueryParams: QueryStringToQueryParams = (queryString) => {
  const pairs = queryString.slice(1).split("&");
  return pairs.reduce((acc, pair) => {
    const [key, value] = pair.split("=");

    if (key && value) {
      return {
        ...acc,
        [key]: value,
      };
    }

    return acc;
  }, {});
};

type UseQueryParams = () => [QueryParams, SetQueryParams];
const useQueryParams: UseQueryParams = () => {
  const queryParams = queryStringToQueryParams(location.search);
  const [params, setParams] = useState<QueryParams>(queryParams);

  useEffect(() => {
    const handler = () => {
      const queryParams = queryStringToQueryParams(location.search);
      const queryParamsKeys = Object.keys(queryParams);

      const decodedQueryParams = queryParamsKeys.reduce((acc, key) => {
        const value = queryParams[key] || "";

        const decodedValue = decodeURIComponent(value);
        return {
          ...acc,
          [key]: decodedValue,
        };
      }, {});

      setParams(decodedQueryParams);
    };

    window.addEventListener("popstate", handler);

    return () => window.removeEventListener("popstate", handler);
  }, []);

  const setQueryParams: SetQueryParams = useCallback((queryParams) => {
    const queryString = queryParamsToQueryString(queryParams);
    const url = location.pathname + queryString;
    history.pushState({}, "", url);
  }, []);

  return [params, setQueryParams];
};

export default useQueryParams;
