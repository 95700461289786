import { QueryParams, SetQueryParams } from "@hooks/useQueryParams";
import { SortOrder } from "antd/lib/table/interface";

type HandleSortPublishingQueue = {
  sortValue: string;
  sortDirection: SortOrder;
  queryParams: QueryParams;
  setQueryParams: SetQueryParams;
};

export const handleSortPublishingQueue = ({
  sortValue,
  sortDirection,
  queryParams,
  setQueryParams,
}: HandleSortPublishingQueue) => {
  const newQueryParams = { ...queryParams };

  if (sortDirection) {
    newQueryParams["sortValue"] = sortValue;
    newQueryParams["sortDirection"] = sortDirection;
  } else {
    delete newQueryParams["sortValue"];
    delete newQueryParams["sortDirection"];
  }

  setQueryParams(newQueryParams);
};
