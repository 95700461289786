import { Typography, Tabs, notification, ConfigProvider } from "antd";
import { PublishingQueue } from "@components/PublishingQueue";
import { RepublishingQueue } from "@components/RepublishingQueue";
import { TAB_NAMES, ANTD_EXTERNAL_VERSION_LESS } from "./constants";
import useQueryParams from "./hooks/useQueryParams";
import { Dashboard } from "@components/Dashboard";

const { TabPane } = Tabs;

notification.config({
  top: 55,
  prefixCls: `${ANTD_EXTERNAL_VERSION_LESS}-notification`,
});

export default function Root() {
  const [params, setQueryParams] = useQueryParams();
  const activeTabKey = params["activeTab"] || TAB_NAMES.PUBLISHING;

  const onTabChange = (activeTab: string) => {
    if (
      activeTab !== TAB_NAMES.PUBLISHING &&
      activeTab !== TAB_NAMES.REPUBLISHING
    ) {
      return console.error("Root::onTabChange => wrong tab", activeTab);
    }

    setQueryParams({ activeTab });
  };

  return (
    <ConfigProvider prefixCls={ANTD_EXTERNAL_VERSION_LESS}>
      <div style={{ padding: "20px 10px" }}>
        <Typography.Title>PUBLISHING QUEUE</Typography.Title>
        <div className="card-container">
          <Tabs
            type="card"
            destroyInactiveTabPane={true}
            activeKey={activeTabKey}
            onChange={onTabChange}
          >
            <TabPane tab="Publishing Queue" key={TAB_NAMES.PUBLISHING}>
              <section style={{ paddingBottom: "10px" }}>
                <Dashboard setQueryParams={setQueryParams} />
              </section>
              <PublishingQueue
                queryParams={params}
                setQueryParams={setQueryParams}
                active={activeTabKey === TAB_NAMES.PUBLISHING}
              />
            </TabPane>
            <TabPane tab="Republishing Queue" key={TAB_NAMES.REPUBLISHING}>
              <RepublishingQueue
                queryParams={params}
                setQueryParams={setQueryParams}
                active={activeTabKey === TAB_NAMES.REPUBLISHING}
              />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </ConfigProvider>
  );
}
