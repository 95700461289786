import { DataQaSelector } from "@enums/dataQaSelector";
import { Typography, Pagination as AntPagination } from "antd";

interface Props {
  totalEntityCount: number;
  page: number;
  pageSize: number;
  onChange: (page: number) => void;
}

export default function Pagination(props: Props) {
  const { totalEntityCount, page, pageSize, onChange } = props;

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Typography.Title
        level={4}
        data-qa-selector={DataQaSelector.totalEntityCount}
      >
        {totalEntityCount} total results
      </Typography.Title>

      <AntPagination
        data-qa-selector={DataQaSelector.pagination}
        total={totalEntityCount}
        defaultPageSize={pageSize}
        current={page + 1}
        showSizeChanger={false}
        onChange={onChange}
        itemRender={(page, type, originalElement) => (
          <span
            data-qa-selector={DataQaSelector.paginationItem}
            data-qa-selector-page={type === "page" ? page : type}
          >
            {originalElement}
          </span>
        )}
      />
    </div>
  );
}
