import { useFetchRepublishingQueueTableData } from "@hooks/useFetchRepublishingQueueTableData";
import { QueryParams, SetQueryParams } from "@hooks/useQueryParams";
import { PAGE_SIZE, TAB_NAMES } from "@src/constants";
import { handleSortPublishingQueue } from "@utils/handleSortPublishingQueue";
import { Space } from "antd";
import RepublishingFilter from "../RepublishingFilter";
import Pagination from "../Pagination";
import DataTable from "../RepublishingDataTable";

type RepublishingQueueType = {
  queryParams: QueryParams;
  setQueryParams: SetQueryParams;
  active: boolean;
};

export const RepublishingQueue = ({
  queryParams,
  setQueryParams,
  active,
}: RepublishingQueueType): JSX.Element => {
  const { isDataLoading, data } = useFetchRepublishingQueueTableData({
    queryParams,
    active,
  });

  const handlePageChange = (page: number) => {
    setQueryParams({ ...queryParams, page: String(page) });
  };

  const handleFilterChange = (filter: QueryParams | undefined) => {
    if (!filter) {
      setQueryParams({ activeTab: TAB_NAMES.REPUBLISHING, page: "1" });
      return;
    }

    const queryParamsWithResetPage = {
      ...queryParams,
      page: "1",
    };
    setQueryParams({ ...queryParamsWithResetPage, ...filter });
  };

  const {
    dictionaries,
    items = [],
    page = 1,
    pageSize = PAGE_SIZE,
    totalEntityCount = 0,
  } = data ?? {};

  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      <Pagination
        totalEntityCount={totalEntityCount}
        page={page}
        pageSize={pageSize}
        onChange={handlePageChange}
      />
      <RepublishingFilter
        values={queryParams}
        onFiltersChange={handleFilterChange}
      />
      <DataTable
        dataSource={items}
        onSortChange={(sortValue, sortDirection) =>
          handleSortPublishingQueue({
            sortValue,
            sortDirection,
            queryParams,
            setQueryParams,
          })
        }
        loading={isDataLoading}
        dictionaries={dictionaries}
        onFilterChange={(filterData) => handleFilterChange(filterData)}
        filterValues={queryParams}
      />

      <Pagination
        totalEntityCount={totalEntityCount}
        page={page}
        pageSize={pageSize}
        onChange={handlePageChange}
      />
    </Space>
  );
};
