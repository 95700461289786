import { notification } from "antd";
import { METHODS, callApi } from "./callApi";
import { formatServerErrors } from "./formatServerErrors";
import { retailKibanaLogger } from "@utils/logger";

export const message = "Server error";

export type Options = {
  signal?: AbortSignal;
  errorHandler?: (error: ServerError[]) => ServerError[];
};

export const callGql =
  (url: string) =>
  async <T>(
    query: string,
    variables?: unknown,
    options?: Options
  ): Promise<T> => {
    try {
      const response = await callApi<{ data: T; errors: ServerError[] }>(
        METHODS.POST,
        url,
        {
          query,
          variables,
        },
        options?.signal
      );
      const errors =
        response.errors?.length && options?.errorHandler
          ? options.errorHandler(response.errors)
          : response.errors;

      if (errors?.length) {
        notification.error({
          message,
          description: formatServerErrors(response.errors),
        });
        retailKibanaLogger.error(message, errors);
      }
      return response.data;
    } catch (e) {
      if (e instanceof Error && e.name !== "AbortError") {
        notification.error({
          message,
          description: e.message,
        });
        retailKibanaLogger.error(message, e);
      }
      throw e;
    }
  };
