import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import { DataQaSelector } from "@enums/dataQaSelector";
import type {
  PublishingBlockerSubreason,
  PublishingQueueDictionaries,
} from "@gql/publishing-queue-types";
import { QueryParams } from "@hooks/useQueryParams";
import { QueryParamsMappers } from "@utils/mappers";
import { Button, Checkbox, Input, Select, Space } from "antd";
import { ChangeEvent, KeyboardEvent, useState } from "react";

interface Props {
  values: {
    searchText?: string;
    preparationState?: string;
    preparationReady?: QueryParamsMappers["preparationReady"];
    publishingBlockerSubreason?: string;
  };
  onFiltersChange: (arg: QueryParams | undefined) => void;
  dictionaries?: PublishingQueueDictionaries;
  availableBlockerSubreasons: PublishingBlockerSubreason[];
}

export default function PublishingFilter({
  values,
  onFiltersChange,
  dictionaries,
  availableBlockerSubreasons,
}: Props) {
  const [filters, setFilters] = useState({
    ...values,
    publishingBlockerSubreason:
      values.publishingBlockerSubreason?.split(",") ?? [],
  });

  // useEffect(() => {
  //   setFilters({
  //     ...values,
  //     publishingBlockerSubreason:
  //       values.publishingBlockerSubreason?.split(",") ?? [],
  //   });
  // }, [values]);

  const handleResetFilters = () => {
    setFilters({ publishingBlockerSubreason: [] });
    onFiltersChange(undefined);
  };

  const handleApplyFilters = () => {
    onFiltersChange({
      ...filters,
      publishingBlockerSubreason: filters.publishingBlockerSubreason.join(","),
    });
  };

  const onFilterChange = (
    val: Record<string, string | string[] | undefined>
  ) => {
    setFilters({ ...filters, ...val });
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleApplyFilters();
    }
  };

  return (
    <Space>
      <Input
        onKeyPress={handleKeyPress}
        data-qa-selector={DataQaSelector.vinOrStockNumberSearchInput}
        name="searchText"
        value={filters["searchText"]}
        style={{ width: 200 }}
        placeholder="VIN or StockNumber"
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          onFilterChange({ searchText: e.target.value });
        }}
      />
      <Select
        allowClear
        data-qa-selector={DataQaSelector.preparationStateFilter}
        placeholder="Preparation State"
        value={filters["preparationState"]}
        style={{ width: 200 }}
        options={dictionaries?.preparationStateOptions ?? []}
        onChange={(e: string) => {
          onFilterChange({ preparationState: e });
        }}
      />
      <Select
        allowClear
        mode="multiple"
        maxTagCount="responsive"
        data-qa-selector={DataQaSelector.activePublishingBlockersFilter}
        placeholder="Active Blockers"
        value={filters["publishingBlockerSubreason"]}
        style={{ width: 320 }}
        options={availableBlockerSubreasons.map(
          ({ subreason, subreasonId }) => ({
            label: subreason,
            value: subreasonId,
            key: subreasonId,
          })
        )}
        onChange={(e: string[]) => {
          onFilterChange({ publishingBlockerSubreason: e });
        }}
      />
      <Checkbox
        data-qa-selector={DataQaSelector.preparationReady}
        defaultChecked={filters.preparationReady === "true"}
        checked={filters.preparationReady === "true"}
        onChange={({ target }) => {
          const preparationReady = String(target.checked);
          onFilterChange({ preparationReady });
        }}
        onKeyPress={handleKeyPress}
      >
        Preparation ready
      </Checkbox>
      <Button
        data-qa-selector={DataQaSelector.resetFiltersButton}
        icon={<DeleteOutlined />}
        onClick={handleResetFilters}
      ></Button>
      <Button
        data-qa-selector={DataQaSelector.searchButton}
        icon={<SearchOutlined />}
        type={"primary"}
        onClick={handleApplyFilters}
      ></Button>
    </Space>
  );
}
