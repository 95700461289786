import { Table, Tag, Button, Tooltip } from "antd";
import { FormOutlined } from "@ant-design/icons";
import { formatDate } from "@utils/date";
import {
  PublishingQueueDictionaries,
  PublishingQueueItem,
  User,
  Price,
} from "@gql/publishing-queue-types";
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
  TablePaginationConfig,
} from "antd/es/table/interface";
import { Assignee } from "../Assignee";
import { DataQaSelector } from "@enums/dataQaSelector";
import { getCurrencySymbolByCode } from "@utils/currency";
import { SortOrder } from "antd/lib/table/interface";
import { QueryParams } from "@hooks/useQueryParams";

const { Column } = Table;

interface Props {
  dataSource: PublishingQueueItem[];
  onSortChange: (sortValue: string, sortDirection: SortOrder) => void;
  onFilterChange: (arg: QueryParams) => void;
  dictionaries?: PublishingQueueDictionaries;
  filterValues?: QueryParams;
  loading?: boolean;
  allAssignableUsers?: User[];
}

type TableTitle = {
  key: string;
  title: string;
};

export const renderTitle = ({ key, title }: TableTitle) => {
  return <span data-qa-selector={`${key}-header`}>{title}</span>;
};

export const renderDate = (date: string | null, index: number, key: string) => {
  if (!date) {
    return (
      <Tag data-qa-selector={`${key}-value-${index}`} color="error">
        Not ready
      </Tag>
    );
  }

  return (
    <span data-qa-selector={`${key}-value-${index}`}>{formatDate(date)}</span>
  );
};

export const renderAssignee = (
  record: PublishingQueueItem,
  index: number,
  allAssignableUsers?: Array<User>
) => {
  const filteredUsersByCountry = allAssignableUsers?.filter((user) => {
    if (user.userId === null) {
      return true;
    } else {
      return !!user.countries.find((country) => {
        if (country === "*") {
          return true;
        } else {
          return country === record.retailCountry;
        }
      });
    }
  });

  return (
    <Assignee
      record={record}
      assignableUsers={filteredUsersByCountry}
      index={index}
    />
  );
};

export const renderActionBtn = (adId: string) => {
  return (
    <Button
      size="small"
      type="link"
      icon={<FormOutlined />}
      href={`/en/ad/${adId}`}
      data-qa-selector={DataQaSelector.editButton}
    />
  );
};

export const renderPrice = (price: Price, index: number) => {
  const { majorUnits, currencyCode } = price;
  const formattedPrice = majorUnits
    ? `${getCurrencySymbolByCode(currencyCode)} ${majorUnits}`
    : null;
  return formattedPrice ? (
    <span data-qa-selector={`current-price-value-${index}`}>
      {formattedPrice}
    </span>
  ) : (
    <></>
  );
};

const renderBlockers = (text: string[], index: number) => {
  if (!text || text.length === 0) return <>-</>;
  return (
    <Tooltip
      placement="top"
      overlayStyle={{ maxWidth: "500px" }}
      title={text.map((item, i) => (
        <p key={i}>
          {i + 1} - {item}
        </p>
      ))}
    >
      <Tag data-qa-selector={`blockers-list-${index}`} color={"pink"}>
        {text.length}
      </Tag>
    </Tooltip>
  );
};

export default function DataTable({
  dataSource,
  onSortChange,
  loading = false,
  dictionaries,
  onFilterChange,
  filterValues,
  allAssignableUsers,
}: Props) {
  const { retailCountryOptions = [], assigneeOptions = [] } =
    dictionaries ?? {};
  const { retailCountries, assignees } = filterValues ?? {};

  const handleChange = (
    _: TablePaginationConfig,
    filter: Record<string, FilterValue | null>,
    sort:
      | SorterResult<PublishingQueueItem>
      | SorterResult<PublishingQueueItem>[],
    { action }: TableCurrentDataSource<PublishingQueueItem>
  ) => {
    if (action === "sort") {
      const sortItem = Array.isArray(sort) ? sort[0] : sort;

      if (sortItem?.field && sortItem?.order) {
        onSortChange(String(sortItem.field), sortItem.order);
      }
      return;
    }

    if (action === "filter") {
      onFilterChange(
        Object.fromEntries(
          Object.entries(filter).map(([key, value]) => [
            key,
            Array.isArray(value) ? value.join(",") : value ?? undefined,
          ])
        )
      );
      return;
    }
  };

  return (
    <Table
      data-qa-selector={DataQaSelector.pqDataTable}
      pagination={false}
      size="small"
      dataSource={dataSource}
      rowKey="vin"
      onChange={handleChange}
      loading={loading}
    >
      <Column
        dataIndex="retailCountry"
        key="retailCountries"
        title={() =>
          renderTitle({ key: "retailCountry", title: "Retail Country" })
        }
        render={(text, _record, index) => {
          return (
            <span data-qa-selector={`retailCountry-value-${index}`}>
              {text}
            </span>
          );
        }}
        filters={retailCountryOptions.map(({ label, value }) => ({
          text: label,
          value,
        }))}
        filteredValue={retailCountries?.split(",") || []}
      />
      <Column
        ellipsis
        dataIndex="vin"
        key="vin"
        title={() => renderTitle({ key: "vin", title: "VIN and Stock Number" })}
        render={(text, _record: PublishingQueueItem, index) => {
          return (
            <div data-qa-selector={`vin-stockNumber-${index}`}>
              <p data-qa-selector={`vin-value-${index}`}>{text}</p>
              <p data-qa-selector={`stockNumber-value-${index}`}>
                {_record.stockNumber}
              </p>
            </div>
          );
        }}
      />
      <Column
        title={() =>
          renderTitle({ key: "retailReadySince", title: "Retail Ready Since" })
        }
        dataIndex="retailReadySince"
        key="retailReadySince"
        render={(text, _record, index) =>
          renderDate(text, index, "retailReadySince")
        }
      />
      <Column
        dataIndex="publishingReadySince"
        key="publishingReadySince"
        title={() =>
          renderTitle({
            key: "publishingReadySince",
            title: "Preparation Ready Since",
          })
        }
        render={(text, _record, index) => {
          return (
            <span data-qa-selector={`publishingReadySince-value-${index}`}>
              {text ?? "-"}
            </span>
          );
        }}
      />
      <Column
        dataIndex="publishingBlockerSubreasons"
        key="publishingBlockerSubreason"
        title={() =>
          renderTitle({
            key: "publishingBlockerSubreasons",
            title: "Active blockers",
          })
        }
        render={(text, _, index: number) => renderBlockers(text, index)}
      />
      <Column
        dataIndex="assignee"
        key="assignees"
        title={() => renderTitle({ key: "assignee", title: "Assigned to" })}
        filters={assigneeOptions.map(({ userName, userId }) => {
          return { text: userName, value: userId ?? "" };
        })}
        filteredValue={assignees?.split(",") || []}
        filterSearch={true}
        render={(_: User, record: PublishingQueueItem, index: number) =>
          renderAssignee(record, index, allAssignableUsers)
        }
      />
      <Column
        dataIndex="preparationState"
        key="preparationState"
        title={() =>
          renderTitle({
            key: "preparationState",
            title: "Preparation State",
          })
        }
        render={(text, _record, index) => {
          return (
            <span data-qa-selector={`preparationState-value-${index}`}>
              {text ?? "-"}
            </span>
          );
        }}
      />
      <Column
        dataIndex="adId"
        key=""
        width={30}
        title=""
        render={renderActionBtn}
      />
    </Table>
  );
}
