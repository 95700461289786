import { v4 as uuid } from "uuid";

import {
  createDevelopmentLogFn,
  createApiLogFn,
  createClientSideLogger,
} from "@retail/kibana-logger/lib/client";
import { LOGGER_ENDPOINTS } from "@src/constants";

interface LogObject {
  message?: string;
  traceId?: string;
  label?: string | undefined;
}

export class RetailKibanaLogger {
  // FIX:ME @retail/kibana-logger does not provides proper types
  // replace the any with proper type once the types are fixed in the lib
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _clientSideLogger: any;

  constructor() {
    this.prepareLogger();
  }

  private isProduction(): boolean {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return window?.__config?.APP_ENV === "prod";
  }

  private prepareLogger(): void {
    const logFn =
      process.env["NODE_ENV"] === "development"
        ? createDevelopmentLogFn()
        : createApiLogFn({
            appName: __PACKAGE_NAME__,
            appVersion: __PACKAGE_VERSION__,
            endpoint: this.isProduction()
              ? LOGGER_ENDPOINTS.PROD
              : LOGGER_ENDPOINTS.QA,
          });
    this._clientSideLogger = createClientSideLogger({}, logFn);
  }

  private getLogObject(message: string, label?: string): LogObject {
    return {
      message,
      traceId: uuid(),
      label,
    };
  }

  private formatError(err: unknown): string {
    if (err instanceof Error) {
      return JSON.stringify({
        message: err.message,
        stack: err.stack,
      });
    }
    return JSON.stringify(err);
  }

  public error(label: string, err: unknown): void {
    this._clientSideLogger.error(
      this.getLogObject(this.formatError(err), label)
    );
  }

  public info(message: string): void {
    this._clientSideLogger.info(this.getLogObject(message));
  }
}

export const retailKibanaLogger = new RetailKibanaLogger();
