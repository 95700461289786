export enum DataQaSelector {
  country = "country",
  assignee = "assignee",
  select = "select",
  option = "option",
  searchButton = "searchButton",
  resetFiltersButton = "resetFilterButton",
  vinOrStockNumberSearchInput = "vin-or-stock-number-search-input",
  editButton = "editButton",
  index = "index",
  vin = "vin",
  vinAndStockNumber = "vin-stockNumber",
  stockNumber = "stockNumber",
  countryCode = "countryCode",
  vehicle = "vehicle",
  firstPublishedAt = "firstPublishedAt",
  daysOnline = "daysOnline",
  hasImages = "hasImages",
  retailStatus = "retailStatus",
  wkdaStatus = "wkdaStatus",
  autoheroStatus = "autoheroStatus",
  mobileDeStatus = "mobileDeStatus",
  totalEntityCount = "totalEntityCount",
  pagination = "pagination",
  indexHeader = "indexHeader",
  vinHeader = "vinHeader",
  stockNoHeader = "stockNoHeader",
  countryHeader = "countryHeader",
  vehicleHeader = "vehicleHeader",
  firstPublishedHeader = "firstPublishedHeader",
  onlineDaysHeader = "onlineDaysHeader",
  hasImagesHeader = "hasImagesHeader",
  retailStatusHeader = "retailStatusHeader",
  wkdaStatusHeader = "wkdaStatusHeader",
  autoheroStatusHeader = "autoheroStatusHeader",
  mobileDeStatusHeader = "mobiledeStatusHeader",
  noData = "noData",
  loading = "loading",
  tryFindInAdMgmt = "tryFindInAdMgmt",
  adMgmtLink = "adMgmtLink",
  assignSelectBtn = "assignSelectBtn",
  assigneeName = "assigneeName",
  pqDataTable = "adlistTable",
  retailReadySince = "retailReadySince",
  selectLocation = "selectLocation",
  selectShots = "selectShots",
  preparationReady = "preparationReady",
  offline = "offline",
  paginationItem = "paginationItem",
  preparationStateFilter = "preparationStateFilter",
  activePublishingBlockersFilter = "activePublishingBlockersFilter",
  dashboardTable = "dashboardTable",
  purchasableSince = "purchasableSince",
}
