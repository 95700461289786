import { useEffect, useMemo, useState } from "react";
import { queryParamsToRequestParams, QueryParamsMappers } from "@utils/mappers";
import { publishingQueueSdk } from "@utils/sdk";
import {
  PublishingQueueDictionaries,
  PublishingQueueItem,
  PublishingQueueQuery,
  PublishingBlockerSubreason,
} from "@gql/publishing-queue-types";

type useFetchPublishingQueueTableData = (args: {
  queryParams: QueryParamsMappers;
  active: boolean;
}) => {
  isDataLoading: boolean;
  data: {
    items: PublishingQueueItem[];
    page: number;
    totalEntityCount: number;
    pageSize: number;
    dictionaries: PublishingQueueDictionaries;
    availableBlockerSubreasons: PublishingBlockerSubreason[];
  } | null;
};

export const useFetchPublishingQueueTableData: useFetchPublishingQueueTableData =
  ({ queryParams, active }) => {
    const [responseData, setResponseData] =
      useState<PublishingQueueQuery | null>(null);
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);

    useEffect(() => {
      const variables = queryParamsToRequestParams(queryParams);
      const abortController = new AbortController();
      const fetchData = async () => {
        try {
          setIsDataLoading(true);
          const responseData = await publishingQueueSdk.publishingQueue(
            variables,
            {
              signal: abortController.signal,
            }
          );
          setResponseData(responseData);
          setIsDataLoading(false);
        } catch (err: unknown) {
          console.info(err);
        }
      };

      if (active) {
        fetchData();
      }

      return () => {
        setIsDataLoading(false);
        abortController.abort();
      };
    }, [queryParams, setIsDataLoading, setResponseData, active]);

    const items = useMemo(
      () =>
        responseData?.publishingQueue?.items.reduce<PublishingQueueItem[]>(
          (acc, item) => (item !== null ? [...acc, item] : acc),
          []
        ) || [],
      [responseData]
    );

    return {
      isDataLoading,
      data: responseData
        ? {
            items,
            page: responseData.publishingQueue.pageInfo.page,
            totalEntityCount:
              responseData.publishingQueue.pageInfo.totalEntityCount,
            pageSize: responseData.publishingQueue.pageInfo.pageSize,
            dictionaries: responseData.publishingQueueDictionaries,
            availableBlockerSubreasons:
              responseData.publishingQueue.availableBlockerSubreasons,
          }
        : null,
    };
  };
