import {
  Filter,
  PublishingQueueQueryVariables,
  SortDirection,
} from "@gql/publishing-queue-types";
import { FilterValue, SortOrder } from "antd/lib/table/interface";
import { PAGE_SIZE, TabName } from "../constants";

export interface QueryParamsMappers {
  [key: string]: string | undefined;

  searchText?: string;
  retailCountries?: string;
  minNumberOfPhotos?: string;
  sortValue?: string;
  sortDirection?: NonNullable<SortOrder>;
  page?: string;
  activeTab?: TabName;
  assignees?: string;
  publishingBlockerSubreason?: string;
  preparationState?: string;
  preparationReady?: "true" | "false";
  minHoursSincePreparationReady?: string;
}
type QueryParamsToRequestParams = (
  queryParams: QueryParamsMappers,
  isRepublishing?: boolean
) => PublishingQueueQueryVariables;

export const queryParamsToRequestParams: QueryParamsToRequestParams = (
  queryParams
) => {
  const filter: Filter = {};
  if (queryParams.searchText) {
    filter.searchText = queryParams.searchText;
  }

  if (queryParams.retailCountries) {
    filter.retailCountries = queryParams.retailCountries.split(",");
  }

  if (queryParams.publishingBlockerSubreason) {
    filter.publishingBlockerSubreason =
      queryParams.publishingBlockerSubreason.split(",");
  }

  if (queryParams.assignees) {
    filter.assignees = queryParams.assignees?.split(",");
  }

  if (queryParams.preparationReady) {
    const boolPreparationReady = queryParams.preparationReady === "true";

    filter.preparationReady = boolPreparationReady;
  }

  if (queryParams.preparationState) {
    filter.preparationState = queryParams.preparationState;
  }

  if (queryParams.minNumberOfPhotos) {
    const [minNumberOfPhotos] = queryParams.minNumberOfPhotos.split(",");

    if (minNumberOfPhotos) {
      filter.minNumberOfPhotos = Number(minNumberOfPhotos);
    }
  }

  if (queryParams.minHoursSincePreparationReady) {
    const minHoursSincePreparationReady = parseInt(
      queryParams.minHoursSincePreparationReady
    );
    if (!Number.isNaN(minHoursSincePreparationReady))
      filter.minHoursSincePreparationReady = minHoursSincePreparationReady;
  }

  const pager = {
    page: queryParams.page ? Number(queryParams.page) - 1 : 0,
    pageSize: PAGE_SIZE,
  };

  return {
    filter,
    sort: {
      value: queryParams.sortValue || "",
      direction:
        queryParams.sortDirection === "descend"
          ? SortDirection.Desc
          : SortDirection.Asc,
    },
    pager,
  };
};

export const tableFiltersToQueryParams = (
  filters: Record<string, FilterValue | null>
): QueryParamsMappers => {
  const currentFilters: QueryParamsMappers = {};
  Object.keys(filters).forEach((key) => {
    if (key === "numberOfPhotos" && filters["numberOfPhotos"]) {
      currentFilters["minNumberOfPhotos"] = filters[
        "numberOfPhotos"
      ][0] as string;
    } else if (key !== "numberOfPhotos" && filters[key]?.length) {
      const filter = filters[key];
      currentFilters[key] = Array.isArray(filter)
        ? filter.join(",")
        : filter ?? "";
    }
  });
  return currentFilters;
};
