import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import { DataQaSelector } from "@enums/dataQaSelector";

import { QueryParams } from "@hooks/useQueryParams";

import { Button, Input, Space } from "antd";
import { ChangeEvent, KeyboardEvent, useState } from "react";

interface Props {
  values: {
    searchText?: string;
  };
  onFiltersChange: (arg: QueryParams | undefined) => void;
}

export default function RepublishingFilter({ values, onFiltersChange }: Props) {
  const [filters, setFilters] = useState({
    ...values,
  });

  const handleResetFilters = () => {
    setFilters({});
    onFiltersChange(undefined);
  };

  const handleApplyFilters = () => {
    onFiltersChange({
      ...filters,
    });
  };

  const onFilterChange = (
    val: Record<string, string | string[] | undefined>
  ) => {
    setFilters({ ...filters, ...val });
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleApplyFilters();
    }
  };

  return (
    <Space>
      <Input
        onKeyPress={handleKeyPress}
        data-qa-selector={DataQaSelector.vinOrStockNumberSearchInput}
        name="searchText"
        value={filters["searchText"]}
        style={{ width: 200 }}
        placeholder="VIN or StockNumber"
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          onFilterChange({ searchText: e.target.value });
        }}
      />
      <Button
        data-qa-selector={DataQaSelector.resetFiltersButton}
        icon={<DeleteOutlined />}
        onClick={handleResetFilters}
      ></Button>
      <Button
        data-qa-selector={DataQaSelector.searchButton}
        icon={<SearchOutlined />}
        type={"primary"}
        onClick={handleApplyFilters}
      ></Button>
    </Space>
  );
}
