import { PublishingQueueItem, User } from "@gql/publishing-queue-types";
import { Select, Button, notification } from "antd";
import { publishingQueueSdk } from "@utils/sdk";
import { UserSwitchOutlined } from "@ant-design/icons";
import { useState, useMemo } from "react";
import { DataQaSelector } from "@enums/dataQaSelector";
import { retailKibanaLogger } from "@utils/logger";

type AssigneeProps = {
  record: PublishingQueueItem;
  index: number;
  assignableUsers?: User[];
};
const errorMessage = "Something went wrong during assignment";

export const Assignee = ({
  record,
  assignableUsers = [],
  index,
}: AssigneeProps): JSX.Element => {
  const [isListHidden, setListHidden] = useState(true);
  const [assignee, setAssignee] = useState<User | null>(
    record.assignee ?? null
  );
  const [selectValue, setSelectValue] = useState<null | string>(() => {
    const { assignee } = record;
    const foundUser =
      assignee &&
      assignableUsers.find((user) => user.userId === assignee.userId);
    if (assignee && foundUser) {
      return foundUser.userId ?? null;
    } else if (record.assignee === null) {
      return "";
    } else {
      return null;
    }
  });

  const assigneeMappedOptions = useMemo(
    () =>
      assignableUsers.map((user: User) => ({
        label: user.userName,
        value: user.userId ?? "",
      })),
    [assignableUsers]
  );

  const toggleButton = (
    <Button
      size="small"
      type="link"
      icon={<UserSwitchOutlined />}
      data-qa-selector={`${DataQaSelector.assignSelectBtn}-button-${index}`}
      onClick={() => {
        setListHidden(!isListHidden);
      }}
    />
  );

  const handleAssignUser = async (userId?: string) => {
    try {
      if (userId === assignee?.userId) return;
      const isAssigned = await publishingQueueSdk.assignUserToQueueAd({
        adId: record.adId,
        oldUserId: assignee?.userId ?? null,
        userId: userId || null,
      });
      if (!isAssigned.assignUserToQueueAd) {
        retailKibanaLogger.error(errorMessage, null);
        throw new Error(errorMessage);
      }
      notification.success({
        message: userId
          ? `Ad was successfully assigned`
          : `Ad was successfully unassigned`,
      });
      const selectedAssignee = assignableUsers.find(
        (user) => user.userId === userId
      );
      setAssignee(selectedAssignee || null);
      setSelectValue(selectedAssignee?.userId ?? "");
      setListHidden(true);
    } catch (_) {
      console.log(errorMessage);
    }
  };

  const usersSelectList = isListHidden ? null : (
    <Select
      allowClear
      showSearch
      filterOption={(input, option) =>
        option?.label?.toLowerCase().includes(input.toLowerCase()) ?? false
      }
      data-qa-selector={DataQaSelector.select}
      data-qa-selector-name={`${DataQaSelector.assignee}-select-${index}`}
      autoFocus
      open={true}
      style={{ width: 150 }}
      listHeight={128}
      value={selectValue}
      options={assigneeMappedOptions}
      onBlur={() => setListHidden(true)}
      onChange={handleAssignUser}
    />
  );

  if (assignableUsers.length !== 0) {
    return assignee ? (
      <span>
        {toggleButton}{" "}
        <span
          data-qa-selector={`${DataQaSelector.assigneeName}-value-${index}`}
        >
          {assignee.userName}
        </span>{" "}
        {usersSelectList}
      </span>
    ) : (
      <span data-qa-selector={`${DataQaSelector.assigneeName}-value-${index}`}>
        {toggleButton}-{usersSelectList}
      </span>
    );
  } else {
    return assignee ? (
      <span data-qa-selector={`${DataQaSelector.assigneeName}-value-${index}`}>
        {assignee.userName}
      </span>
    ) : (
      <span data-qa-selector={`${DataQaSelector.assigneeName}-value-${index}`}>
        -
      </span>
    );
  }
};
