import { DataQaSelector } from "@enums/dataQaSelector";
import { KpiDashboardRow } from "@gql/publishing-queue-types";
import { SetQueryParams } from "@hooks/useQueryParams";
import { TableColumnsType, Typography } from "antd";
import { queryParamsToQueryString } from "@hooks/useQueryParams";

type MakeColumnsProps = {
  rows: KpiDashboardRow[];
  setQueryParams: SetQueryParams;
};
export const makeColumns = ({ rows, setQueryParams }: MakeColumnsProps) => {
  const columns: TableColumnsType<KpiDashboardRow> =
    rows[0]?.valuesByCountry?.map((row) => ({
      title: () => {
        const Component = row.filter ? Typography.Link : "b";

        return (
          <Component
            data-qa-selector={`${DataQaSelector.dashboardTable}-${row.country}-column`}
            onClick={() => {
              row.filter &&
                setQueryParams(row.filter as Record<string, string>);
            }}
          >
            {row.country}
          </Component>
        );
      },
      key: row.country,
      render: (_, record, idx) => {
        const valuesByCountry = record.valuesByCountry?.find(
          (el) => el.country === row.country
        );
        const Component = valuesByCountry?.filter ? Typography.Link : "span";

        return (
          <Component
            data-qa-selector={`${DataQaSelector.dashboardTable}-${record.kpiName}-row-${idx}-${row.country}`}
            data-qa-value={valuesByCountry?.unitCount}
            target="_blank"
            href={
              valuesByCountry?.filter
                ? queryParamsToQueryString(
                    valuesByCountry?.filter as Record<string, string>
                  )
                : undefined
            }
          >
            {valuesByCountry?.unitCount}
          </Component>
        );
      },
    })) ?? [];

  columns.unshift({
    title: "",
    key: "empty",
    dataIndex: "kpiName",
    render: (_, record, idx) => {
      if (record.children && record.children.length > 0)
        return (
          <b
            data-qa-selector={`${DataQaSelector.dashboardTable}-${record.kpiName}-row-${idx}`}
          >
            {record.kpiLabel}
          </b>
        );

      return (
        <span
          data-qa-selector={`${DataQaSelector.dashboardTable}-${record.kpiName}-row-${idx}`}
        >
          {record.kpiLabel}
        </span>
      );
    },
  });

  return columns;
};
