import { Table, Tag, Button } from "antd";
import { FormOutlined } from "@ant-design/icons";
import { formatDate } from "@utils/date";
import {
  PublishingQueueDictionaries,
  RepublishingQueueItem,
} from "@gql/publishing-queue-types";
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
  TablePaginationConfig,
} from "antd/es/table/interface";
import { DataQaSelector } from "@enums/dataQaSelector";
import { SortOrder } from "antd/lib/table/interface";
import { QueryParams } from "@hooks/useQueryParams";

const { Column } = Table;

interface Props {
  dataSource: RepublishingQueueItem[];
  onSortChange: (sortValue: string, sortDirection: SortOrder) => void;
  onFilterChange: (arg: QueryParams) => void;
  dictionaries?: PublishingQueueDictionaries;
  filterValues?: QueryParams;
  loading?: boolean;
}

type TableTitle = {
  key: string;
  title: string;
};

export const renderTitle = ({ key, title }: TableTitle) => {
  return <span data-qa-selector={`${key}-header`}>{title}</span>;
};

export const renderDate = (date: string | null, index: number, key: string) => {
  if (!date) {
    return (
      <Tag data-qa-selector={`${key}-value-${index}`} color="error">
        Not ready
      </Tag>
    );
  }

  return (
    <span data-qa-selector={`${key}-value-${index}`}>{formatDate(date)}</span>
  );
};

export const renderActionBtn = (adId: string) => {
  return (
    <Button
      size="small"
      type="link"
      icon={<FormOutlined />}
      href={`/en/ad/${adId}`}
      data-qa-selector={DataQaSelector.editButton}
    />
  );
};

export const renderPlatforms = (platforms: string[]) => {
  return (
    <>
      {platforms.map((platform) => (
        <Tag data-qa-selector={`${platform}`} key={platform}>
          {platform}
        </Tag>
      ))}
    </>
  );
};

export default function DataTable({
  dataSource,
  onSortChange,
  loading = false,
  dictionaries,
  onFilterChange,
  filterValues,
}: Props) {
  const { retailCountryOptions = [] } = dictionaries ?? {};
  const { retailCountries } = filterValues ?? {};
  const handleChange = (
    _: TablePaginationConfig,
    filter: Record<string, FilterValue | null>,
    sort:
      | SorterResult<RepublishingQueueItem>
      | SorterResult<RepublishingQueueItem>[],
    { action }: TableCurrentDataSource<RepublishingQueueItem>
  ) => {
    if (action === "sort") {
      const sortItem = Array.isArray(sort) ? sort[0] : sort;

      if (sortItem?.field && sortItem?.order) {
        onSortChange(String(sortItem.field), sortItem.order);
      }
      return;
    }
    if (action === "filter") {
      onFilterChange(
        Object.fromEntries(
          Object.entries(filter).map(([key, value]) => [
            key,
            Array.isArray(value) ? value.join(",") : value ?? undefined,
          ])
        )
      );
      return;
    }
  };

  return (
    <Table
      data-qa-selector={DataQaSelector.pqDataTable}
      pagination={false}
      size="small"
      dataSource={dataSource}
      rowKey="stockNumber"
      onChange={handleChange}
      loading={loading}
    >
      <Column
        dataIndex="retailCountry"
        key="retailCountries"
        title={() =>
          renderTitle({ key: "retailCountry", title: "Retail Country" })
        }
        render={(text, _record, index) => {
          return (
            <span data-qa-selector={`retailCountry-value-${index}`}>
              {text}
            </span>
          );
        }}
        filters={retailCountryOptions.map(({ label, value }) => ({
          text: label,
          value,
        }))}
        filteredValue={retailCountries?.split(",") || []}
      />
      <Column
        ellipsis
        dataIndex="vin"
        key="vin"
        title={() => renderTitle({ key: "vin", title: "VIN and Stock Number" })}
        render={(text, _record: RepublishingQueueItem, index) => {
          return (
            <div data-qa-selector={`vin-stockNumber-${index}`}>
              <p data-qa-selector={`vin-value-${index}`}>{text}</p>
              <p data-qa-selector={`stockNumber-value-${index}`}>
                {_record.stockNumber}
              </p>
            </div>
          );
        }}
      />

      <Column
        title={() =>
          renderTitle({
            key: "purchasableSince",
            title: "First Eligible to be purchased",
          })
        }
        dataIndex="purchasableSince"
        key="purchasableSince"
        render={(text, _record, index) =>
          renderDate(text, index, "purchasableSince")
        }
      />
      <Column
        dataIndex="republishingPlatforms"
        key="republishingPlatforms"
        title={() =>
          renderTitle({
            key: "republishingPlatforms",
            title: "Republishing on",
          })
        }
        render={(platforms) => renderPlatforms(platforms)}
      />
      <Column
        dataIndex="adId"
        key=""
        width={30}
        title=""
        render={renderActionBtn}
      />
    </Table>
  );
}
