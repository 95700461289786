import PackageJson from "../package.json";
import {
  PublishingStatus,
  PublishingPlatform,
} from "@gql/publishing-queue-types";

export const PAGE_SIZE = 50;

export const TAB_NAMES = {
  PUBLISHING: "publishing",
  REPUBLISHING: "republishing",
} as const;

type TabNameKeys = keyof typeof TAB_NAMES;
export type TabName = typeof TAB_NAMES[TabNameKeys];

export const PUBLISHING_QUEUE_API_URL =
  "/api/v1/retail-publishing-queue-gql/graphql";

export const DATA_TEMPORARY_UNAVAILABLE = "DATA_TEMPORARY_UNAVAILABLE";
export const TABLE_COLUMN_TITLE = {
  priceManagementGroupLabel: "Group",
  vin: "VIN",
  stockNumber: "StockNo.",
  countryCode: "Country",
  vehicle: "Vehicle",
  firstPublishedAt: "First Published",
  daysOnline: "Online days",
  hasImages: "Has images",
  retailStatus: "Status",
  wkdaStatus: "WKDA",
  autoheroStatus: "Autohero",
  mobileDeStatus: "Mobile.de",
};

// antd-4.21.7
export const ANTD_EXTERNAL_VERSION = `antd-${PackageJson.dependencies.antd}`;
// antd-4-21-7 css classes
export const ANTD_EXTERNAL_VERSION_LESS = ANTD_EXTERNAL_VERSION.replace(
  /\./g,
  "-"
);

export const LOGGER_ENDPOINTS = {
  PROD: "https://retail-logger.prod.retail.auto1.cloud/v1/log",
  QA: "https://retail-logger.1.qa.retail.auto1.cloud/v1/log",
} as const;

export enum ColorEnum {
  red = "#ff0039",
  black = "#222",
  green = "#3fb618",
  gray = "#8c8c8c",
  warning = "#9e85f3",
}

export const statusColorMap: Record<PublishingStatus, ColorEnum> = {
  [PublishingStatus.Published]: ColorEnum.black,
  [PublishingStatus.Unpublished]: ColorEnum.gray,
  [PublishingStatus.Error]: ColorEnum.red,
  [PublishingStatus.ComingSoon]: ColorEnum.warning,
};

export type Platform = Exclude<keyof PublishingPlatform, "__typename">;

export enum PlatformMap {
  autohero = "AH",
  mobilede = "Mobile.de",
  autoscout24 = "AS24",
}
