import { DataQaSelector } from "@enums/dataQaSelector";
import { KpiDashboardRow } from "@gql/publishing-queue-types";
import { useFetchGetKpiDashboardQueue } from "@hooks/useFetchGetKpiDashboardQueue";
import { SetQueryParams } from "@hooks/useQueryParams";
import { Table, TableColumnsType } from "antd";
import { makeColumns } from "./makeColumns";

type DashboardProps = {
  setQueryParams: SetQueryParams;
};
export const Dashboard = ({ setQueryParams }: DashboardProps) => {
  const { data, isDataLoading } = useFetchGetKpiDashboardQueue();
  if (isDataLoading) return <div>Dashboard: loading table data...</div>;

  if (!data) return <div>Dashboard error: no data</div>;

  const rows = data.kpiDashboard.rows;
  if (!rows) return <div>Dashboard error: no rows </div>;
  if (!rows[0]?.valuesByCountry?.length)
    return <div>Dashboard error: no columns</div>;

  const columns: TableColumnsType<KpiDashboardRow> = makeColumns({
    rows,
    setQueryParams,
  });

  return (
    <Table
      data-qa-selector={DataQaSelector.dashboardTable}
      dataSource={rows}
      rowKey={(record) => record.kpiLabel}
      columns={columns}
      pagination={false}
    />
  );
};
