import { useEffect, useState } from "react";
import { publishingQueueSdk } from "@utils/sdk";
import {
  SearchAssignableUsersInput,
  SearchAssignableUsersQuery,
} from "@gql/publishing-queue-types";

type useFetchAssignableUsers = (args: {
  input: SearchAssignableUsersInput;
}) => {
  isDataLoading: boolean;
  data: SearchAssignableUsersQuery | null;
};

export const useFetchAssignableUsers: useFetchAssignableUsers = (input) => {
  const [data, setResponseData] = useState<SearchAssignableUsersQuery | null>(
    null
  );
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchData = async () => {
      try {
        setIsDataLoading(true);
        const users = await publishingQueueSdk.searchAssignableUsers(input);
        setResponseData(users);
        setIsDataLoading(false);
      } catch (err: unknown) {
        console.info(err);
      }
    };
    fetchData();
    return () => {
      setIsDataLoading(false);
      abortController.abort();
    };
  }, []);

  return {
    isDataLoading,
    data,
  };
};
