/* istanbul ignore next */
/* eslint-disable */
/* @ts-nocheck */
// THIS FILE IS GENERATED, DO NOT EDIT!

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Mutation = {
  __typename?: "Mutation";
  assignUserToQueueAd: Scalars["Boolean"];
};

export type MutationAssignUserToQueueAdArgs = {
  adId: Scalars["String"];
  oldUserId?: InputMaybe<Scalars["String"]>;
  userId?: InputMaybe<Scalars["String"]>;
};

export type Filter = {
  searchText?: InputMaybe<Scalars["String"]>;
  retailCountries?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  publishingBlockerSubreason?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  minNumberOfPhotos?: InputMaybe<Scalars["Int"]>;
  maxNumberOfPhotos?: InputMaybe<Scalars["Int"]>;
  offline?: InputMaybe<Scalars["Boolean"]>;
  retailReady?: InputMaybe<Scalars["Boolean"]>;
  assignees?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  preparationState?: InputMaybe<Scalars["String"]>;
  preparationReady?: InputMaybe<Scalars["Boolean"]>;
  minHoursSincePreparationReady?: InputMaybe<Scalars["Int"]>;
};

export const enum SortDirection {
  Asc = "ASC",
  Desc = "DESC",
}

export const enum Category {
  Publishing = "PUBLISHING",
  Republishing = "REPUBLISHING",
}

export type Sort = {
  value: Scalars["String"];
  direction: SortDirection;
};

export type Pager = {
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
};

export type SearchAssignableUsersInput = {
  retailCountries: Array<Scalars["String"]>;
};

export type DictionaryItem = {
  __typename?: "DictionaryItem";
  label: Scalars["String"];
  value: Scalars["String"];
};

export type PublishingQueueDictionaries = {
  __typename?: "PublishingQueueDictionaries";
  numberOfPhotosOptions: Array<DictionaryItem>;
  retailCountryOptions: Array<DictionaryItem>;
  assigneeOptions: Array<User>;
  preparationStateOptions: Array<DictionaryItem>;
};

export type PublishingQueue = {
  __typename?: "PublishingQueue";
  pageInfo: PageInfo;
  availableBlockerSubreasons: Array<PublishingBlockerSubreason>;
  items: Array<Maybe<PublishingQueueItem>>;
};

export type PublishingBlockerSubreason = {
  __typename?: "PublishingBlockerSubreason";
  subreasonId: Scalars["String"];
  subreason: Scalars["String"];
};

export type RepublishingQueue = {
  __typename?: "RepublishingQueue";
  pageInfo: PageInfo;
  items: Array<Maybe<RepublishingQueueItem>>;
};

export type PageInfo = {
  __typename?: "PageInfo";
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  totalEntityCount: Scalars["Int"];
  totalPageCount: Scalars["Int"];
};

export const enum PublishingStatus {
  ComingSoon = "COMING_SOON",
  Published = "PUBLISHED",
  Unpublished = "UNPUBLISHED",
  Error = "ERROR",
}

export type PublishingPlatform = {
  __typename?: "PublishingPlatform";
  autohero?: Maybe<PublishingStatus>;
  mobilede?: Maybe<PublishingStatus>;
  autoscout24?: Maybe<PublishingStatus>;
};

export type Price = {
  __typename?: "Price";
  majorUnits?: Maybe<Scalars["Int"]>;
  currencyCode?: Maybe<Scalars["String"]>;
};

export type PublishingQueueItem = {
  __typename?: "PublishingQueueItem";
  adId: Scalars["ID"];
  vin?: Maybe<Scalars["String"]>;
  stockNumber?: Maybe<Scalars["String"]>;
  retailCountry?: Maybe<Scalars["String"]>;
  retailReadySince?: Maybe<Scalars["String"]>;
  publishingPlatform?: Maybe<PublishingPlatform>;
  assignee?: Maybe<User>;
  publishingReadySince?: Maybe<Scalars["String"]>;
  publishingBlockerSubreasons?: Maybe<Array<Maybe<Scalars["String"]>>>;
  preparationState?: Maybe<Scalars["String"]>;
};

export type RepublishingQueueItem = {
  __typename?: "RepublishingQueueItem";
  adId: Scalars["ID"];
  vin?: Maybe<Scalars["String"]>;
  stockNumber?: Maybe<Scalars["String"]>;
  retailCountry?: Maybe<Scalars["String"]>;
  purchasableSince?: Maybe<Scalars["String"]>;
  republishingPlatforms: Array<Maybe<Scalars["String"]>>;
};

export type User = {
  __typename?: "User";
  userId?: Maybe<Scalars["ID"]>;
  userName: Scalars["String"];
  countries: Array<Scalars["String"]>;
};

export type KpiDashboard = {
  __typename?: "KpiDashboard";
  rows?: Maybe<Array<KpiDashboardRow>>;
};

export type KpiDashboardRow = {
  __typename?: "KpiDashboardRow";
  kpiName: Scalars["String"];
  kpiLabel: Scalars["String"];
  valuesByCountry?: Maybe<Array<CountryKpi>>;
  children?: Maybe<Array<KpiDashboardRow>>;
};

export type CountryKpi = {
  __typename?: "CountryKpi";
  country: Scalars["String"];
  unitCount: Scalars["Int"];
  filter?: Maybe<KpiFilter>;
};

export type KpiFilter = {
  __typename?: "KpiFilter";
  searchText?: Maybe<Scalars["String"]>;
  retailCountries?: Maybe<Array<Maybe<Scalars["String"]>>>;
  publishingBlockerSubreason?: Maybe<Array<Maybe<Scalars["String"]>>>;
  minNumberOfPhotos?: Maybe<Scalars["Int"]>;
  maxNumberOfPhotos?: Maybe<Scalars["Int"]>;
  offline?: Maybe<Scalars["Boolean"]>;
  retailReady?: Maybe<Scalars["Boolean"]>;
  assignees?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  preparationState?: Maybe<Scalars["String"]>;
  preparationReady?: Maybe<Scalars["Boolean"]>;
  minHoursSincePreparationReady?: Maybe<Scalars["Int"]>;
};

export type Query = {
  __typename?: "Query";
  publishingQueue: PublishingQueue;
  republishingQueue: RepublishingQueue;
  publishingQueueDictionaries: PublishingQueueDictionaries;
  searchAssignableUsers: Array<User>;
  kpiDashboard: KpiDashboard;
};

export type QueryPublishingQueueArgs = {
  filter?: InputMaybe<Filter>;
  sort?: InputMaybe<Sort>;
  pager: Pager;
};

export type QueryRepublishingQueueArgs = {
  filter?: InputMaybe<Filter>;
  sort?: InputMaybe<Sort>;
  pager: Pager;
};

export type QuerySearchAssignableUsersArgs = {
  input: SearchAssignableUsersInput;
};

export type AssignUserToQueueAdMutationVariables = Exact<{
  adId: Scalars["String"];
  oldUserId?: InputMaybe<Scalars["String"]>;
  userId?: InputMaybe<Scalars["String"]>;
}>;

export type AssignUserToQueueAdMutation = {
  __typename?: "Mutation";
  assignUserToQueueAd: boolean;
};

export type KpiDashboardRowsFragment = {
  __typename?: "KpiDashboardRow";
  kpiName: string;
  kpiLabel: string;
  valuesByCountry?: Array<{
    __typename?: "CountryKpi";
    country: string;
    unitCount: number;
    filter?: {
      __typename?: "KpiFilter";
      searchText?: string | null;
      minHoursSincePreparationReady?: number | null;
      retailCountries?: Array<string | null> | null;
      publishingBlockerSubreason?: Array<string | null> | null;
      minNumberOfPhotos?: number | null;
      maxNumberOfPhotos?: number | null;
      offline?: boolean | null;
      retailReady?: boolean | null;
      assignees?: Array<string | null> | null;
      preparationState?: string | null;
      preparationReady?: boolean | null;
    } | null;
  }> | null;
};

export type KpiDashboardRowsNestedFragment = {
  __typename?: "KpiDashboardRow";
  kpiName: string;
  kpiLabel: string;
  children?: Array<{
    __typename?: "KpiDashboardRow";
    kpiName: string;
    kpiLabel: string;
    children?: Array<{
      __typename?: "KpiDashboardRow";
      kpiName: string;
      kpiLabel: string;
      children?: Array<{
        __typename?: "KpiDashboardRow";
        kpiName: string;
        kpiLabel: string;
        children?: Array<{
          __typename?: "KpiDashboardRow";
          kpiName: string;
          kpiLabel: string;
          children?: Array<{
            __typename?: "KpiDashboardRow";
            kpiName: string;
            kpiLabel: string;
            children?: Array<{
              __typename?: "KpiDashboardRow";
              kpiName: string;
              kpiLabel: string;
              children?: Array<{
                __typename?: "KpiDashboardRow";
                kpiName: string;
                kpiLabel: string;
                children?: Array<{
                  __typename?: "KpiDashboardRow";
                  kpiName: string;
                  kpiLabel: string;
                  valuesByCountry?: Array<{
                    __typename?: "CountryKpi";
                    country: string;
                    unitCount: number;
                    filter?: {
                      __typename?: "KpiFilter";
                      searchText?: string | null;
                      minHoursSincePreparationReady?: number | null;
                      retailCountries?: Array<string | null> | null;
                      publishingBlockerSubreason?: Array<string | null> | null;
                      minNumberOfPhotos?: number | null;
                      maxNumberOfPhotos?: number | null;
                      offline?: boolean | null;
                      retailReady?: boolean | null;
                      assignees?: Array<string | null> | null;
                      preparationState?: string | null;
                      preparationReady?: boolean | null;
                    } | null;
                  }> | null;
                }> | null;
                valuesByCountry?: Array<{
                  __typename?: "CountryKpi";
                  country: string;
                  unitCount: number;
                  filter?: {
                    __typename?: "KpiFilter";
                    searchText?: string | null;
                    minHoursSincePreparationReady?: number | null;
                    retailCountries?: Array<string | null> | null;
                    publishingBlockerSubreason?: Array<string | null> | null;
                    minNumberOfPhotos?: number | null;
                    maxNumberOfPhotos?: number | null;
                    offline?: boolean | null;
                    retailReady?: boolean | null;
                    assignees?: Array<string | null> | null;
                    preparationState?: string | null;
                    preparationReady?: boolean | null;
                  } | null;
                }> | null;
              }> | null;
              valuesByCountry?: Array<{
                __typename?: "CountryKpi";
                country: string;
                unitCount: number;
                filter?: {
                  __typename?: "KpiFilter";
                  searchText?: string | null;
                  minHoursSincePreparationReady?: number | null;
                  retailCountries?: Array<string | null> | null;
                  publishingBlockerSubreason?: Array<string | null> | null;
                  minNumberOfPhotos?: number | null;
                  maxNumberOfPhotos?: number | null;
                  offline?: boolean | null;
                  retailReady?: boolean | null;
                  assignees?: Array<string | null> | null;
                  preparationState?: string | null;
                  preparationReady?: boolean | null;
                } | null;
              }> | null;
            }> | null;
            valuesByCountry?: Array<{
              __typename?: "CountryKpi";
              country: string;
              unitCount: number;
              filter?: {
                __typename?: "KpiFilter";
                searchText?: string | null;
                minHoursSincePreparationReady?: number | null;
                retailCountries?: Array<string | null> | null;
                publishingBlockerSubreason?: Array<string | null> | null;
                minNumberOfPhotos?: number | null;
                maxNumberOfPhotos?: number | null;
                offline?: boolean | null;
                retailReady?: boolean | null;
                assignees?: Array<string | null> | null;
                preparationState?: string | null;
                preparationReady?: boolean | null;
              } | null;
            }> | null;
          }> | null;
          valuesByCountry?: Array<{
            __typename?: "CountryKpi";
            country: string;
            unitCount: number;
            filter?: {
              __typename?: "KpiFilter";
              searchText?: string | null;
              minHoursSincePreparationReady?: number | null;
              retailCountries?: Array<string | null> | null;
              publishingBlockerSubreason?: Array<string | null> | null;
              minNumberOfPhotos?: number | null;
              maxNumberOfPhotos?: number | null;
              offline?: boolean | null;
              retailReady?: boolean | null;
              assignees?: Array<string | null> | null;
              preparationState?: string | null;
              preparationReady?: boolean | null;
            } | null;
          }> | null;
        }> | null;
        valuesByCountry?: Array<{
          __typename?: "CountryKpi";
          country: string;
          unitCount: number;
          filter?: {
            __typename?: "KpiFilter";
            searchText?: string | null;
            minHoursSincePreparationReady?: number | null;
            retailCountries?: Array<string | null> | null;
            publishingBlockerSubreason?: Array<string | null> | null;
            minNumberOfPhotos?: number | null;
            maxNumberOfPhotos?: number | null;
            offline?: boolean | null;
            retailReady?: boolean | null;
            assignees?: Array<string | null> | null;
            preparationState?: string | null;
            preparationReady?: boolean | null;
          } | null;
        }> | null;
      }> | null;
      valuesByCountry?: Array<{
        __typename?: "CountryKpi";
        country: string;
        unitCount: number;
        filter?: {
          __typename?: "KpiFilter";
          searchText?: string | null;
          minHoursSincePreparationReady?: number | null;
          retailCountries?: Array<string | null> | null;
          publishingBlockerSubreason?: Array<string | null> | null;
          minNumberOfPhotos?: number | null;
          maxNumberOfPhotos?: number | null;
          offline?: boolean | null;
          retailReady?: boolean | null;
          assignees?: Array<string | null> | null;
          preparationState?: string | null;
          preparationReady?: boolean | null;
        } | null;
      }> | null;
    }> | null;
    valuesByCountry?: Array<{
      __typename?: "CountryKpi";
      country: string;
      unitCount: number;
      filter?: {
        __typename?: "KpiFilter";
        searchText?: string | null;
        minHoursSincePreparationReady?: number | null;
        retailCountries?: Array<string | null> | null;
        publishingBlockerSubreason?: Array<string | null> | null;
        minNumberOfPhotos?: number | null;
        maxNumberOfPhotos?: number | null;
        offline?: boolean | null;
        retailReady?: boolean | null;
        assignees?: Array<string | null> | null;
        preparationState?: string | null;
        preparationReady?: boolean | null;
      } | null;
    }> | null;
  }> | null;
  valuesByCountry?: Array<{
    __typename?: "CountryKpi";
    country: string;
    unitCount: number;
    filter?: {
      __typename?: "KpiFilter";
      searchText?: string | null;
      minHoursSincePreparationReady?: number | null;
      retailCountries?: Array<string | null> | null;
      publishingBlockerSubreason?: Array<string | null> | null;
      minNumberOfPhotos?: number | null;
      maxNumberOfPhotos?: number | null;
      offline?: boolean | null;
      retailReady?: boolean | null;
      assignees?: Array<string | null> | null;
      preparationState?: string | null;
      preparationReady?: boolean | null;
    } | null;
  }> | null;
};

export type GetKpiDashboardQueryVariables = Exact<{ [key: string]: never }>;

export type GetKpiDashboardQuery = {
  __typename?: "Query";
  kpiDashboard: {
    __typename?: "KpiDashboard";
    rows?: Array<{
      __typename?: "KpiDashboardRow";
      kpiName: string;
      kpiLabel: string;
      children?: Array<{
        __typename?: "KpiDashboardRow";
        kpiName: string;
        kpiLabel: string;
        children?: Array<{
          __typename?: "KpiDashboardRow";
          kpiName: string;
          kpiLabel: string;
          children?: Array<{
            __typename?: "KpiDashboardRow";
            kpiName: string;
            kpiLabel: string;
            children?: Array<{
              __typename?: "KpiDashboardRow";
              kpiName: string;
              kpiLabel: string;
              children?: Array<{
                __typename?: "KpiDashboardRow";
                kpiName: string;
                kpiLabel: string;
                children?: Array<{
                  __typename?: "KpiDashboardRow";
                  kpiName: string;
                  kpiLabel: string;
                  children?: Array<{
                    __typename?: "KpiDashboardRow";
                    kpiName: string;
                    kpiLabel: string;
                    children?: Array<{
                      __typename?: "KpiDashboardRow";
                      kpiName: string;
                      kpiLabel: string;
                      valuesByCountry?: Array<{
                        __typename?: "CountryKpi";
                        country: string;
                        unitCount: number;
                        filter?: {
                          __typename?: "KpiFilter";
                          searchText?: string | null;
                          minHoursSincePreparationReady?: number | null;
                          retailCountries?: Array<string | null> | null;
                          publishingBlockerSubreason?: Array<
                            string | null
                          > | null;
                          minNumberOfPhotos?: number | null;
                          maxNumberOfPhotos?: number | null;
                          offline?: boolean | null;
                          retailReady?: boolean | null;
                          assignees?: Array<string | null> | null;
                          preparationState?: string | null;
                          preparationReady?: boolean | null;
                        } | null;
                      }> | null;
                    }> | null;
                    valuesByCountry?: Array<{
                      __typename?: "CountryKpi";
                      country: string;
                      unitCount: number;
                      filter?: {
                        __typename?: "KpiFilter";
                        searchText?: string | null;
                        minHoursSincePreparationReady?: number | null;
                        retailCountries?: Array<string | null> | null;
                        publishingBlockerSubreason?: Array<
                          string | null
                        > | null;
                        minNumberOfPhotos?: number | null;
                        maxNumberOfPhotos?: number | null;
                        offline?: boolean | null;
                        retailReady?: boolean | null;
                        assignees?: Array<string | null> | null;
                        preparationState?: string | null;
                        preparationReady?: boolean | null;
                      } | null;
                    }> | null;
                  }> | null;
                  valuesByCountry?: Array<{
                    __typename?: "CountryKpi";
                    country: string;
                    unitCount: number;
                    filter?: {
                      __typename?: "KpiFilter";
                      searchText?: string | null;
                      minHoursSincePreparationReady?: number | null;
                      retailCountries?: Array<string | null> | null;
                      publishingBlockerSubreason?: Array<string | null> | null;
                      minNumberOfPhotos?: number | null;
                      maxNumberOfPhotos?: number | null;
                      offline?: boolean | null;
                      retailReady?: boolean | null;
                      assignees?: Array<string | null> | null;
                      preparationState?: string | null;
                      preparationReady?: boolean | null;
                    } | null;
                  }> | null;
                }> | null;
                valuesByCountry?: Array<{
                  __typename?: "CountryKpi";
                  country: string;
                  unitCount: number;
                  filter?: {
                    __typename?: "KpiFilter";
                    searchText?: string | null;
                    minHoursSincePreparationReady?: number | null;
                    retailCountries?: Array<string | null> | null;
                    publishingBlockerSubreason?: Array<string | null> | null;
                    minNumberOfPhotos?: number | null;
                    maxNumberOfPhotos?: number | null;
                    offline?: boolean | null;
                    retailReady?: boolean | null;
                    assignees?: Array<string | null> | null;
                    preparationState?: string | null;
                    preparationReady?: boolean | null;
                  } | null;
                }> | null;
              }> | null;
              valuesByCountry?: Array<{
                __typename?: "CountryKpi";
                country: string;
                unitCount: number;
                filter?: {
                  __typename?: "KpiFilter";
                  searchText?: string | null;
                  minHoursSincePreparationReady?: number | null;
                  retailCountries?: Array<string | null> | null;
                  publishingBlockerSubreason?: Array<string | null> | null;
                  minNumberOfPhotos?: number | null;
                  maxNumberOfPhotos?: number | null;
                  offline?: boolean | null;
                  retailReady?: boolean | null;
                  assignees?: Array<string | null> | null;
                  preparationState?: string | null;
                  preparationReady?: boolean | null;
                } | null;
              }> | null;
            }> | null;
            valuesByCountry?: Array<{
              __typename?: "CountryKpi";
              country: string;
              unitCount: number;
              filter?: {
                __typename?: "KpiFilter";
                searchText?: string | null;
                minHoursSincePreparationReady?: number | null;
                retailCountries?: Array<string | null> | null;
                publishingBlockerSubreason?: Array<string | null> | null;
                minNumberOfPhotos?: number | null;
                maxNumberOfPhotos?: number | null;
                offline?: boolean | null;
                retailReady?: boolean | null;
                assignees?: Array<string | null> | null;
                preparationState?: string | null;
                preparationReady?: boolean | null;
              } | null;
            }> | null;
          }> | null;
          valuesByCountry?: Array<{
            __typename?: "CountryKpi";
            country: string;
            unitCount: number;
            filter?: {
              __typename?: "KpiFilter";
              searchText?: string | null;
              minHoursSincePreparationReady?: number | null;
              retailCountries?: Array<string | null> | null;
              publishingBlockerSubreason?: Array<string | null> | null;
              minNumberOfPhotos?: number | null;
              maxNumberOfPhotos?: number | null;
              offline?: boolean | null;
              retailReady?: boolean | null;
              assignees?: Array<string | null> | null;
              preparationState?: string | null;
              preparationReady?: boolean | null;
            } | null;
          }> | null;
        }> | null;
        valuesByCountry?: Array<{
          __typename?: "CountryKpi";
          country: string;
          unitCount: number;
          filter?: {
            __typename?: "KpiFilter";
            searchText?: string | null;
            minHoursSincePreparationReady?: number | null;
            retailCountries?: Array<string | null> | null;
            publishingBlockerSubreason?: Array<string | null> | null;
            minNumberOfPhotos?: number | null;
            maxNumberOfPhotos?: number | null;
            offline?: boolean | null;
            retailReady?: boolean | null;
            assignees?: Array<string | null> | null;
            preparationState?: string | null;
            preparationReady?: boolean | null;
          } | null;
        }> | null;
      }> | null;
      valuesByCountry?: Array<{
        __typename?: "CountryKpi";
        country: string;
        unitCount: number;
        filter?: {
          __typename?: "KpiFilter";
          searchText?: string | null;
          minHoursSincePreparationReady?: number | null;
          retailCountries?: Array<string | null> | null;
          publishingBlockerSubreason?: Array<string | null> | null;
          minNumberOfPhotos?: number | null;
          maxNumberOfPhotos?: number | null;
          offline?: boolean | null;
          retailReady?: boolean | null;
          assignees?: Array<string | null> | null;
          preparationState?: string | null;
          preparationReady?: boolean | null;
        } | null;
      }> | null;
    }> | null;
  };
};

export type PublishingQueueQueryVariables = Exact<{
  filter: Filter;
  sort: Sort;
  pager: Pager;
}>;

export type PublishingQueueQuery = {
  __typename?: "Query";
  publishingQueue: {
    __typename?: "PublishingQueue";
    pageInfo: {
      __typename?: "PageInfo";
      page: number;
      pageSize: number;
      totalEntityCount: number;
      totalPageCount: number;
    };
    availableBlockerSubreasons: Array<{
      __typename?: "PublishingBlockerSubreason";
      subreason: string;
      subreasonId: string;
    }>;
    items: Array<{
      __typename?: "PublishingQueueItem";
      adId: string;
      vin?: string | null;
      stockNumber?: string | null;
      retailCountry?: string | null;
      retailReadySince?: string | null;
      publishingReadySince?: string | null;
      publishingBlockerSubreasons?: Array<string | null> | null;
      preparationState?: string | null;
      assignee?: {
        __typename?: "User";
        userId?: string | null;
        userName: string;
        countries: Array<string>;
      } | null;
      publishingPlatform?: {
        __typename?: "PublishingPlatform";
        autohero?: PublishingStatus | null;
        mobilede?: PublishingStatus | null;
        autoscout24?: PublishingStatus | null;
      } | null;
    } | null>;
  };
  publishingQueueDictionaries: {
    __typename?: "PublishingQueueDictionaries";
    numberOfPhotosOptions: Array<{
      __typename?: "DictionaryItem";
      label: string;
      value: string;
    }>;
    preparationStateOptions: Array<{
      __typename?: "DictionaryItem";
      label: string;
      value: string;
    }>;
    retailCountryOptions: Array<{
      __typename?: "DictionaryItem";
      label: string;
      value: string;
    }>;
    assigneeOptions: Array<{
      __typename?: "User";
      userId?: string | null;
      userName: string;
      countries: Array<string>;
    }>;
  };
};

export type RepublishingQueueQueryVariables = Exact<{
  filter: Filter;
  sort: Sort;
  pager: Pager;
}>;

export type RepublishingQueueQuery = {
  __typename?: "Query";
  republishingQueue: {
    __typename?: "RepublishingQueue";
    pageInfo: {
      __typename?: "PageInfo";
      page: number;
      pageSize: number;
      totalEntityCount: number;
      totalPageCount: number;
    };
    items: Array<{
      __typename?: "RepublishingQueueItem";
      adId: string;
      vin?: string | null;
      stockNumber?: string | null;
      retailCountry?: string | null;
      purchasableSince?: string | null;
      republishingPlatforms: Array<string | null>;
    } | null>;
  };
  publishingQueueDictionaries: {
    __typename?: "PublishingQueueDictionaries";
    numberOfPhotosOptions: Array<{
      __typename?: "DictionaryItem";
      label: string;
      value: string;
    }>;
    preparationStateOptions: Array<{
      __typename?: "DictionaryItem";
      label: string;
      value: string;
    }>;
    retailCountryOptions: Array<{
      __typename?: "DictionaryItem";
      label: string;
      value: string;
    }>;
    assigneeOptions: Array<{
      __typename?: "User";
      userId?: string | null;
      userName: string;
      countries: Array<string>;
    }>;
  };
};

export type SearchAssignableUsersQueryVariables = Exact<{
  input: SearchAssignableUsersInput;
}>;

export type SearchAssignableUsersQuery = {
  __typename?: "Query";
  searchAssignableUsers: Array<{
    __typename?: "User";
    userId?: string | null;
    userName: string;
    countries: Array<string>;
  }>;
};

export declare const assignUserToQueueAd: import("graphql").DocumentNode;
export declare const KpiDashboardRows: import("graphql").DocumentNode;
export declare const KpiDashboardRowsNested: import("graphql").DocumentNode;
export declare const getKpiDashboard: import("graphql").DocumentNode;
export declare const publishingQueue: import("graphql").DocumentNode;
export declare const republishingQueue: import("graphql").DocumentNode;
export declare const searchAssignableUsers: import("graphql").DocumentNode;
export const KpiDashboardRowsFragmentDoc = `
    fragment KpiDashboardRows on KpiDashboardRow {
  kpiName
  kpiLabel
  valuesByCountry {
    filter {
      searchText
      minHoursSincePreparationReady
      retailCountries
      publishingBlockerSubreason
      minNumberOfPhotos
      maxNumberOfPhotos
      offline
      retailReady
      assignees
      preparationState
      preparationReady
    }
    country
    unitCount
  }
}
    `;
export const KpiDashboardRowsNestedFragmentDoc = `
    fragment KpiDashboardRowsNested on KpiDashboardRow {
  ...KpiDashboardRows
  children {
    ...KpiDashboardRows
    children {
      ...KpiDashboardRows
      children {
        ...KpiDashboardRows
        children {
          ...KpiDashboardRows
          children {
            ...KpiDashboardRows
            children {
              ...KpiDashboardRows
              children {
                ...KpiDashboardRows
                children {
                  ...KpiDashboardRows
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${KpiDashboardRowsFragmentDoc}`;
export const AssignUserToQueueAdDocument = `
    mutation assignUserToQueueAd($adId: String!, $oldUserId: String, $userId: String) {
  assignUserToQueueAd(adId: $adId, oldUserId: $oldUserId, userId: $userId)
}
    `;
export const GetKpiDashboardDocument = `
    query getKpiDashboard {
  kpiDashboard {
    rows {
      ...KpiDashboardRowsNested
    }
  }
}
    ${KpiDashboardRowsNestedFragmentDoc}`;
export const PublishingQueueDocument = `
    query publishingQueue($filter: Filter!, $sort: Sort!, $pager: Pager!) {
  publishingQueue(filter: $filter, sort: $sort, pager: $pager) {
    pageInfo {
      page
      pageSize
      totalEntityCount
      totalPageCount
    }
    availableBlockerSubreasons {
      subreason
      subreasonId
    }
    items {
      adId
      assignee {
        userId
        userName
        countries
      }
      vin
      stockNumber
      retailCountry
      retailReadySince
      publishingReadySince
      publishingBlockerSubreasons
      publishingPlatform {
        autohero
        mobilede
        autoscout24
      }
      preparationState
    }
  }
  publishingQueueDictionaries {
    numberOfPhotosOptions {
      label
      value
    }
    preparationStateOptions {
      label
      value
    }
    retailCountryOptions {
      label
      value
    }
    assigneeOptions {
      userId
      userName
      countries
    }
  }
}
    `;
export const RepublishingQueueDocument = `
    query republishingQueue($filter: Filter!, $sort: Sort!, $pager: Pager!) {
  republishingQueue(filter: $filter, sort: $sort, pager: $pager) {
    pageInfo {
      page
      pageSize
      totalEntityCount
      totalPageCount
    }
    items {
      adId
      vin
      stockNumber
      retailCountry
      purchasableSince
      republishingPlatforms
    }
  }
  publishingQueueDictionaries {
    numberOfPhotosOptions {
      label
      value
    }
    preparationStateOptions {
      label
      value
    }
    retailCountryOptions {
      label
      value
    }
    assigneeOptions {
      userId
      userName
      countries
    }
  }
}
    `;
export const SearchAssignableUsersDocument = `
    query searchAssignableUsers($input: SearchAssignableUsersInput!) {
  searchAssignableUsers(input: $input) {
    userId
    userName
    countries
  }
}
    `;
export type Requester<C = {}> = <R, V>(
  doc: string,
  vars?: V,
  options?: C
) => Promise<R>;
export function getSdk<C>(requester: Requester<C>) {
  return {
    assignUserToQueueAd(
      variables: AssignUserToQueueAdMutationVariables,
      options?: C
    ): Promise<AssignUserToQueueAdMutation> {
      return requester<
        AssignUserToQueueAdMutation,
        AssignUserToQueueAdMutationVariables
      >(AssignUserToQueueAdDocument, variables, options);
    },
    getKpiDashboard(
      variables?: GetKpiDashboardQueryVariables,
      options?: C
    ): Promise<GetKpiDashboardQuery> {
      return requester<GetKpiDashboardQuery, GetKpiDashboardQueryVariables>(
        GetKpiDashboardDocument,
        variables,
        options
      );
    },
    publishingQueue(
      variables: PublishingQueueQueryVariables,
      options?: C
    ): Promise<PublishingQueueQuery> {
      return requester<PublishingQueueQuery, PublishingQueueQueryVariables>(
        PublishingQueueDocument,
        variables,
        options
      );
    },
    republishingQueue(
      variables: RepublishingQueueQueryVariables,
      options?: C
    ): Promise<RepublishingQueueQuery> {
      return requester<RepublishingQueueQuery, RepublishingQueueQueryVariables>(
        RepublishingQueueDocument,
        variables,
        options
      );
    },
    searchAssignableUsers(
      variables: SearchAssignableUsersQueryVariables,
      options?: C
    ): Promise<SearchAssignableUsersQuery> {
      return requester<
        SearchAssignableUsersQuery,
        SearchAssignableUsersQueryVariables
      >(SearchAssignableUsersDocument, variables, options);
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
