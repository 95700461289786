import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import Root from "./root.component";

if (__WEBPACK_DEV_SERVER__) {
  require("antd/dist/antd.less");
}

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary: (err: Error) => (
    <div>
      <h2>Something went wrong.</h2>
      <pre>{JSON.stringify(err, null, 2)}</pre>
    </div>
  ),
});

export const { bootstrap, mount, unmount } = lifecycles;
