import { GetKpiDashboardQuery } from "@gql/publishing-queue-types";
import { publishingQueueSdk } from "@utils/sdk";
import { useEffect, useState } from "react";

type useFetchGetKpiDashboardQueueState = {
  isDataLoading: boolean;
  data: GetKpiDashboardQuery | null;
};
type useFetchGetKpiDashboardQueue = () => useFetchGetKpiDashboardQueueState;

export const useFetchGetKpiDashboardQueue: useFetchGetKpiDashboardQueue =
  () => {
    const [responseData, setResponseData] =
      useState<GetKpiDashboardQuery | null>(null);
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);

    useEffect(() => {
      const abortController = new AbortController();
      const fetchData = async () => {
        try {
          setIsDataLoading(true);
          const responseData = await publishingQueueSdk.getKpiDashboard(
            {},
            {
              signal: abortController.signal,
            }
          );
          setResponseData(responseData);
          setIsDataLoading(false);
        } catch (err: unknown) {
          console.info(err);
        }
      };

      fetchData();

      return () => {
        setIsDataLoading(false);
        abortController.abort();
      };
    }, [setIsDataLoading, setResponseData]);

    return {
      isDataLoading,
      data: responseData,
    };
  };
