import { useFetchPublishingQueueTableData } from "@hooks/useFetchPublishingQueueTableData";
import { QueryParams, SetQueryParams } from "@hooks/useQueryParams";
import { PAGE_SIZE, TAB_NAMES } from "@src/constants";
import { useFetchAssignableUsers } from "@src/hooks/useFetchAssignableUsers";
import { handleSortPublishingQueue } from "@utils/handleSortPublishingQueue";
import { Space } from "antd";
import PublishingFilter from "../PublishingFilter";
import Pagination from "../Pagination";
import DataTable from "../PublishingDataTable";

type PublishingQueueType = {
  queryParams: QueryParams;
  setQueryParams: SetQueryParams;
  active: boolean;
};

export const PublishingQueue = ({
  queryParams,
  setQueryParams,
  active,
}: PublishingQueueType): JSX.Element => {
  const { data: assignableUsers } = useFetchAssignableUsers({
    input: { retailCountries: [] },
  });
  const { searchAssignableUsers: allAssignableUsers } = assignableUsers ?? {};

  const { data, isDataLoading } = useFetchPublishingQueueTableData({
    queryParams,
    active,
  });

  const handlePageChange = (page: number) => {
    setQueryParams({ ...queryParams, page: String(page) });
  };

  const handleFilterChange = (filter: QueryParams | undefined) => {
    if (!filter) {
      setQueryParams({
        activeTab: TAB_NAMES.PUBLISHING,
        page: "1",
      });
      return;
    }

    const queryParamsWithResetPage: QueryParams = {
      ...queryParams,
      page: "1",
    };
    setQueryParams({ ...queryParamsWithResetPage, ...filter });
  };

  const {
    dictionaries,
    page = 1,
    items = [],
    pageSize = PAGE_SIZE,
    totalEntityCount = 0,
    availableBlockerSubreasons = [],
  } = data ?? {};

  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      <Pagination
        totalEntityCount={totalEntityCount}
        page={page}
        pageSize={pageSize}
        onChange={handlePageChange}
      />
      <PublishingFilter
        values={queryParams}
        onFiltersChange={handleFilterChange}
        dictionaries={dictionaries}
        availableBlockerSubreasons={availableBlockerSubreasons}
      />
      <DataTable
        dataSource={items}
        onSortChange={(sortValue, sortDirection) =>
          handleSortPublishingQueue({
            sortValue,
            sortDirection,
            queryParams,
            setQueryParams,
          })
        }
        loading={isDataLoading}
        dictionaries={dictionaries}
        onFilterChange={(filterData) => handleFilterChange(filterData)}
        filterValues={queryParams}
        allAssignableUsers={allAssignableUsers}
      />

      <Pagination
        totalEntityCount={totalEntityCount}
        page={page}
        pageSize={pageSize}
        onChange={handlePageChange}
      />
    </Space>
  );
};
