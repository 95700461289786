import { getCookieByName } from "./getCookieByName";

export enum METHODS {
  POST = "POST",
}

type ApiCallBody = {
  query: string;
  variables?: unknown;
};

/**
 * There is a race condition between setting an accessToken cookie in the back-office
 * and reading in the MFE. Reading accessToken in a next loop tick resolves this condition.
 */
const getAccessToken = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(getCookieByName("t"));
    });
  });
};

export const callApi = async <T>(
  method: METHODS,
  url: string,
  body?: ApiCallBody,
  signal?: AbortSignal
): Promise<T> => {
  const accessToken = await getAccessToken();

  const headers = {
    "access-domain": "ad.management",
    accept: "application/json, text/plain, */*",
    "content-type": "application/json;charset=UTF-8",
    authorization: `Bearer ${accessToken}`,
  };

  const response = await fetch(url, {
    method,
    headers,
    body: JSON.stringify(body),
    signal,
  });
  return response.json();
};
